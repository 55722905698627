/** @jsx jsx */
import React from 'react'
import { css, jsx } from '@emotion/react'
import Layout from '../../components/Layout'
import ThemeWrapper from '../../components/Theme'
import { Column, ColumnWrap, ColumnWrapper, Title, Text, Subtitle, Button } from '@swp/components'
import SiteHeader from '../../components/de/SiteHeader'

export default function Page(props) {
  return (
    <ThemeWrapper>
      <Layout title={"Na zakázku"}>
        <SiteHeader />

        <Column className="pb--20 pt--80" name={"info"}>
          
          <ColumnWrap className="column__flex --center el--1 mt--30 pb--40 pt--50" style={{"maxWidth":900}} columns={"1"}>
            
            <ColumnWrapper className="--left">
              
              <Title className="title-box" style={{"maxWidth":""}} content={"Auf die Bestellung"}>
              </Title>

              <Text className="text-box" content={"Fangen Sie an Ihren Text zu schreiben. Dieser Bereich ist für Ihren Text bestimmt. Bei Bedarf können Sie die Farbe des Textes, die Schriftgröße und den Zeilenabstand ändern und sogar die maximale Breite des Textes in Pixeln einstellen."}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--30 pt--30" name={"epdhjuigsbv"}>
          
          <ColumnWrap className="column__flex js-anim  --anim2 --anim-s4 --center el--2 pb--50 flex--top" anim={"2"} animS={"4"} style={{"maxWidth":900}} columns={"2"}>
            
            <ColumnWrapper className="--left mb--0 mt--0 pb--0 pt--0">
              
              <Subtitle className="subtitle-box" content={"Süßgebäck"}>
              </Subtitle>

            </ColumnWrapper>

            <ColumnWrapper className="--left mt--0 pt--0">
              
              <Text className="text-box" content={"Fangen Sie an Ihren Text zu schreiben. Dieser Bereich ist für Ihren Text bestimmt. Bei Bedarf können Sie die Farbe des Textblocks, die Schriftgröße und den Zeilenabstand ändern oder den Text ausrichten."}>
              </Text>

              <Subtitle className="subtitle-box" content={"17€"}>
              </Subtitle>

            </ColumnWrapper>

          </ColumnWrap>

          <ColumnWrap className="column__flex js-anim  --anim2 --anim-s4 --center el--2 pb--50 flex--top" anim={"2"} animS={"4"} style={{"maxWidth":900}} columns={"2"}>
            
            <ColumnWrapper className="--left mb--0 mt--0 pb--0 pt--0">
              
              <Subtitle className="subtitle-box" content={"Salzgebäck"}>
              </Subtitle>

            </ColumnWrapper>

            <ColumnWrapper className="--left mt--0 pt--0">
              
              <Text className="text-box" content={"Fangen Sie an Ihren Text zu schreiben. Dieser Bereich ist für Ihren Text bestimmt. Bei Bedarf können Sie die Farbe des Textblocks, die Schriftgröße und den Zeilenabstand ändern oder den Text ausrichten."}>
              </Text>

              <Subtitle className="subtitle-box" content={"17€"}>
              </Subtitle>

            </ColumnWrapper>

          </ColumnWrap>

          <ColumnWrap className="column__flex js-anim  --anim2 --anim-s4 --center el--2 pb--50 flex--top" anim={"2"} animS={"4"} style={{"maxWidth":900}} columns={"2"}>
            
            <ColumnWrapper className="--left mb--0 mt--0 pb--0 pt--0">
              
              <Subtitle className="subtitle-box" content={"Anderes Gebäck"}>
              </Subtitle>

            </ColumnWrapper>

            <ColumnWrapper className="--left mt--0 pt--0">
              
              <Text className="text-box" content={"Fangen Sie an Ihren Text zu schreiben. Dieser Bereich ist für Ihren Text bestimmt. Bei Bedarf können Sie die Farbe des Textblocks, die Schriftgröße und den Zeilenabstand ändern oder den Text ausrichten."}>
              </Text>

              <Subtitle className="subtitle-box" content={"17€"}>
              </Subtitle>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--80 pt--50" name={"vyzva"}>
          
          <ColumnWrap className="column__flex js-anim  --anim2 --anim-s4 --center el--1 pb--80 pt--80" anim={"2"} animS={"4"} style={{"maxWidth":1100,"backgroundColor":"rgba(247, 247, 247, 1)"}} columns={"1"}>
            
            <ColumnWrapper className="pb--20 pt--20" style={{"maxWidth":534}}>
              
              <Title className="title-box" content={"Laden Sie das vollständige Angebot in .pdf herunter"}>
              </Title>

              <Button className="btn-box btn-box--hvr2" href={"/de/"} content={"Herunterladen"}>
              </Button>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="js-anim  --anim3 --anim-s4 pb--50 pl--40 pr--40 pt--40" anim={"3"} name={"partei"} animS={"4"}>
          
          <ColumnWrap className="column__flex el--3 --full pl--20 pr--20 flex--bottom" style={{"maxWidth":""}} columns={"3"} fullscreen={true}>
            
            <ColumnWrapper >
              
              <Text className="text-box" style={{"maxWidth":254}} content={"Backerei Straßenname<br>Oeder Weg 22<br>"}>
              </Text>

              <Text className="text-box" style={{"maxWidth":254}} content={"+49 797 811 2X05<br>info@vase-stranky.com<br>"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper className="--center">
              
              <Text className="text-box" style={{"maxWidth":254}} content={"<a href=\"https://saywebpage.com\">Webproduktion stránek</a>"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper className="--right">
              
              <Text className="text-box" style={{"maxWidth":254}} content={"Mo—Fr<br>7:00—15:00<br>"}>
              </Text>

              <Text className="text-box" style={{"maxWidth":254}} content={"Sa—So<br>7:00—15:00<br>"}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>

      </Layout>
    </ThemeWrapper>
  )
}